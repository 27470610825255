import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Alert } from '@js/Models/Alert.js';

export const useAlertStore = () => {
    return defineStore('alerts', () => {
        const items = ref([]);

        function setItems(newItems) {
            items.value.push(...newItems.map((item) => new Alert(item.alertType, item.text)));
        }

        function resetItems(newItems) {
            items.value = newItems.map((item) => new Alert(item.alertType, item.text));
        }

        return { items, setItems, resetItems };
    })();
};
