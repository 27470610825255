export default {
    mounted(el, binding) {
        const spinnerBackground = document.createElement('div');
        spinnerBackground.className = 'spinner-background';
        spinnerBackground.style.display = 'none';

        const spinner = document.createElement('div');
        spinner.className = 'loading-spinner';

        spinnerBackground.appendChild(spinner);

        el.spinner = spinnerBackground;
        el.appendChild(spinnerBackground);

        if (binding.value) {
            spinnerBackground.style.display = 'block';
        }
    },
    updated(el, binding) {
        if (binding.value) {
            el.style.position = 'relative';
            el.spinner.style.display = 'block';

            return;
        }

        el.spinner.style.display = 'none';
    },
    unmounted(el) {
        if (el.spinner) {
            el.removeChild(el.spinner);
            delete el.spinner;
        }
    },
};
