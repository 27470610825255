import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createPinia } from 'pinia';
import ClickOutside from '@js/Directives/ClickOutside.js';
import Loading from '@js/Directives/Loading.js';
import TooltipDirective from '@js/Directives/TooltipDirective.js';
import * as Sentry from "@sentry/vue";
import axios from 'axios';
import { useAlertStore } from '@js/Stores/AlertStore.js';
import { useCommonStore } from '@js/Stores/CommonStore.js';

const pinia = createPinia();

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app =  createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .directive('click-outside', ClickOutside)
            .directive('tooltip', TooltipDirective)
            .directive('loading', Loading);

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ? import.meta.env.VITE_SENTRY_ENVIRONMENT : import.meta.env.VITE_APP_ENV,
            tracesSampleRate: import.meta.env.VITE_APP_ENV !== 'production' ? 1.0 : 0.2,
            integrations: [Sentry.browserTracingIntegration()],
        });

        app.mount(el);

        delete el.dataset.page;

        return app;
    },
    progress: {
        color: '#4B5563',
    },
}).then();

// Need to fire click events on enter for active element, for accessibility
document.addEventListener('keypress', function (event) {
    if (event.key === 'Enter') {
        const focusedElement = document.activeElement;
        if (focusedElement && focusedElement.matches('div,a')) {
            focusedElement.click();
        }
    }
});

// Handle alerts returned in rpc requests
axios.interceptors.response.use(function (response) {
    if (response.data.alerts) {
        const store = useAlertStore();
        store.setItems(response.data.alerts);
    }

    return response;
});

// Handle prepending store slug on The stage
axios.interceptors.request.use(function (config) {
    const shouldPrepend = import.meta.env.VITE_USES_PREFIX_ROUTING;
    if (shouldPrepend === 'true') {
        const commonStore = useCommonStore();
        const urlSplit = config.url.split('/');

        if (config.url.startsWith('http')) {
            const firstPart = urlSplit.slice(0, 3).join('/');
            const secondPart = urlSplit.slice(3).join('/');

            config.url = firstPart + '/' + commonStore.storeData.storeDomain + '/' + secondPart;

            return config;
        }

        config.url = '/' + commonStore.storeData.storeDomain + config.url;
    }

    return config;
});
