export default {
    mounted(el, binding) {
        if (!binding.value) {
            return;
        }

        el.setAttribute("data-tooltip", binding.value?.text || binding.value);
        el.classList.add("with-tooltip");

        // Create tooltip with correct modifier classes
        const div = document.createElement('div');
        div.classList.add('tooltip');
        div.classList.add(getSizeClass(binding.modifiers));
        if (binding.modifiers.center) {
            div.classList.add('text-center');
        }

        // Parse html
        const template = document.createElement('template');
        template.innerHTML =  binding.value?.text || binding.value;

        div.append(template.content);
        el.append(div);
    },
};

function getSizeClass(modifiers) {
    if (modifiers.sm) {
        return 'sm';
    } else if (modifiers.md) {
        return 'md';
    } else if (modifiers.lg) {
        return 'lg';
    } else if (modifiers.xl) {
        return 'xl';
    }

    return 'md';
}
