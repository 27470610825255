/**
 * @typedef {Object} AlertType
 * @property {string} WARNING - The warning type.
 * @property {string} ERROR - The error type.
 * @property {string} SUCCESS - The error type.
 */

export class Alert {
    /** @type {keyof AlertType} */
    type = AlertType.SUCCESS;
    text = '';
    id = 0;

    /**
     * @param {keyof AlertType} type
     * @param {string} text
     */
    constructor(type, text) {
        this.type = type;
        this.text = text;
        this.id = Math.random().toString(16).slice(2)
    }
}

export class AlertType {
    static WARNING = 'WARNING';
    static ERROR = 'ERROR';
    static SUCCESS = 'SUCCESS';
}
